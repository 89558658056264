@import url(http://fonts.googleapis.com/css?family=Anton|Oswald|Oxygen);

/* @font-face {
  font-family: "Anton", "Oswald", "Oxygen";
  src: url("https://fonts.googleapis.com/css?family=Anton|Oswald|Oxygen")
  format('svg'); font-weight: normal; font-style: normal;
} */

/* GENERAL STYLING */

body {
  height: 100%;
  margin: 0;
  padding: 0;
  display: flex;
  flex-direction: column;
  background-color: black;
  font-family: 'Oxygen';
  font-size: larger;
  font-weight: 100;
  cursor: default;
}

body p {
  font-family: 'Oxygen';
}

.container {
  height: auto;
  align-items: center;
  justify-content: center;
  min-height: 80vh;
  margin-bottom: 25px;
}

.homepage {
  background-image: url(/images/feudale-figures_small.png);
  background-repeat: no-repeat;
  background-position: right 300px top 145px;
}

::-webkit-scrollbar{
  height: 8px;
  width: 8px;
  background: rgb(20,19,19);
}

::-webkit-scrollbar-thumb:horizontal{
  background: #000;
  border-radius: 5px;
}

.rounded {
  border-top: 3px solid #bbb;
  border-radius: 5px;
}

/* NAVBAR */

.navbar {
  font-family: 'Anton', 'Arial Narrow Bold', sans-serif;
  font-weight: 500;
  background-image: linear-gradient(rgb(41,47,51), rgb(19,18,18));
}

.navbar-text {
  font-family: 'Oxygen', 'Copperplate Gothic';
}

.navbar .nav-name {
  -webkit-transition: color 3s;
  -moz-transition: color 3s;
  -o-transition: color 3s;
  transition: color 3s;
  color: white;
  font-size: xx-large;
  margin-right: 15px;
  text-decoration: none;
}

.navbar .nav-name:hover {
  color:rgb(204, 181, 65)
}

.navbar a {
  text-decoration: none;
  padding: 0 7px 0 7px;
  color: #fff;
}

.navbar a:hover {
  text-decoration: none;
  padding: 0 7px 0 7px;
  color: rgba(255, 255, 255, 0.5);
}

.navbar a:visited {
  color: rgba(255, 255, 255, 0.5);
}

/* HOMEPAGE */

#div1 {
  background-color: #000;
  min-height: 50vh;
  padding: 20px;
}

#div2 {
  background-color: rgb(29, 31, 33, 0.6);
  min-height: 50vh;
  padding: 20px;
}

#div3 {
  background-color: #000;
  min-height: 50vh;
  padding: 20px;
}

.home-text {
  line-height: 180%;
  display: inline-block;
  width: 60%;
}

#img-main {
  display: block;
  margin: auto;
  padding: 0;
  width: 50%;
  -moz-object-fit: contain;
  object-fit: contain;
}

.divpics {
  margin: auto;
  width: 500px;
  border-radius: 5px;
  box-shadow: 0px 0px 15px 15px #232323;
}

#div1vid {
  width:420px;
  height:315px;
  border-radius: 5px;
  box-shadow: 0px 0px 15px 15px #232323;
}

/* HEADERS & PARAGRAPHS */

.headers {
  background-color: rgb(20,19,19);
  min-width: 50vw;
}

.headers h2 {
  font-family: 'Oswald';
}

h1 {
  color:azure;
  text-align: center;
  font-family: 'Anton', 'impact';
  font-size: 75px;
}

h2 {
  font-family: 'Oswald';
  color:azure;
  text-align: center;
  font-size: 50px;
}

.div2 h2 {
  font-family: 'Oswald';
  padding: 50px;
}

.div2 p {
  padding: 0 5px 0 5px;
  line-height: 180%;
  width: 50%;
  margin: auto;
}

h3 {
  color:azure;
  text-align: center;
  font-family: 'Anton', 'impact';
  font-size: 30px;
}

.card-body h3 {
  font-family: 'Oxygen';
}

/* DIVS & BODY ELEMENTS */

.header {
  padding: 50px;
}

div {
  color: white;
  text-align: center;
}

.div1 {
  padding: 35px 0 30px 0;
}

.div2 {
  background-color: rgba(29,31,33, 0.3);
  cursor: grab;
  overflow: scroll;
  overflow-x: hidden;
  padding: 100px;
}

.div3 {
  display: block;
  text-align: left;
  font-size: 25px;
  min-height: 65vh;
  min-width: 50vw;
  border-color: #000;
  background-color: rgb(29,31,33);
}

.div4 {
  background-color:black; 
  text-align: center;
  border-style:ridge;
  border-color: white;
}

.card-body {
  font-family: 'Oxygen';
}

.table {
  width: 25%;
  margin-left: auto;
  margin-right: auto;
}

/* SUBSCRIBE FORM */

.main {
  align-items: center;
  margin: 0 auto 50px auto;
  width: 25rem;
  padding: 12px;
  background-color: rgb(29,31,33);
  text-align: center;
  border-radius: 1rem;
  overflow: hidden;
  transition: width .6s cubic-bezier(0.68, -0.55, 0.27, 1.55);
}

.accordion {
  border-radius: 1rem;
}

.accordion-item {
  background-color: #1D1F21;
  border-radius: 1rem !important;
}

.accordion-button {
  background-color: #1D1F21;
  color: #fff;
  font-size: large;
}

.accordion-button.collapsed {
  background-color: #1D1F21;
  color: #fff;
  font-size: large;
}

.accordion-button.collapsed::after {
  background-color: rgba(255, 255, 255, 0.2);
  border-radius: 100%;
}

.accordion-button::after {
  background-color: rgba(255, 255, 255, 0.2);
  border-radius: 100%;
}

.accordion-button:not(.collapsed) {
  background-color: #1D1F21;
}

.sub-button {
  /* background-color: #1D1F21; */
  color: #fff;
  padding: 5px 10px 5px 10px;
  border-radius: 15px;
  margin: 0 0 0 10px;
}

.sub-button:hover {
  opacity: 0.8;
}

/* FOOTER */

.footer-basic {
  padding:45px 0;
  background-color: rgb(20,19,19);
  color:#ffffff;
}

.footer-basic ul {
  padding:0;
  list-style:none;
  text-align:center;
  font-size:18px;
  line-height:1.6;
  margin-bottom:0;
}

.footer-basic li {
  padding: 0 10px;
}

.footer-basic ul a {
  color: inherit;
  text-decoration: none;
  opacity: 0.8;
}

.footer-basic ul a:hover {
  opacity:1;
}

.footer-basic .social {
  text-align: center;
  padding-bottom: 25px;
}

.footer-basic .social > a {
  font-size: 24px;
  width: 40px;
  height: 40px;
  line-height: 40px;
  display: inline-block;
  text-align: center;
  margin: 0 8px;
  color: inherit;
  opacity: 0.75;
}

.footer-basic .social > a:hover {
  opacity: 0.9;
}

.footer-basic .copyright {
  margin-top: 15px;
  text-align: center;
  font-size: 18px;
  color: #aaa;
  margin-bottom: 0;
}
  
/* SOCIAL ICON STYLING */

.socials {
  width: 100%;
  min-height: 15vh;
  background: #000;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -moz-box-pack: center;
  -ms-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -moz-box-align: center;
  -ms-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.fa {
  text-decoration: none;
}

/* Hover Effect */
.fa:hover {
  opacity: 0.7;
}

/* Facebook */
.fa-facebook {
  background: none;
  color: white;
}

/* Twitter */
.fa-twitter {
  background: none;
  color: white;
}
/* Instagram */
.fa-instagram {
  background: none;
  color: white;
}
/* YouTube */
.fa-youtube {
  background: none;
  color: white;
}

/* CONTACT PAGE */

#contact {
  margin-top: 50px;
  margin-bottom: 50px;
}

#g-recaptcha-response {
  display: block !important;
  position: absolute;
  margin: -78px 0 0 0 !important;
  width: 302px !important;
  height: 76px !important;
  z-index: -999999;
  opacity: 0;
}

.li-form {
  list-style-type: none;
  line-height: 50px;
  margin-left: -30px;
}

#frb-form {
  width: 27vw;
}

.form-control {
  border-style: none;
  color: black;
}

input[type="text"], text {
  background-color : white; 
  color: black;
}

textarea.form-control {
  color: black;
  background-color: white;
}

.contact {
  color:white;
  text-align: left;
  font-size: 30px;
}

.contact-list {
  display: block;
  margin: auto;
}

#contact-icons {
  text-align: left;
}

#input-headers {
  text-align: left;
}

.contact-photo {
  display: block;
  margin: auto;
}

#contact-photo {
  border-radius: 5px; /* Thumbnail border */
  box-shadow: 0px 0px 15px 15px #232323;
  width: 500px; /* Thumbnail width */
  margin: 0 0 0 10%;
}

#email-message {
  font-size: medium;
  text-align: center;
}

.confirmation {
  font-style: italic;
}

/* PICTURES PAGE */

#pictures {
  margin-top: 50px;
}

#gallery {
  display: block;
  margin: auto;
  border-style: none;
  border-color: white;
  border-width: 1px;
  border-radius: 5px;
  margin-top: 1%;
  width: 1000px;
  max-width: 1000px;
}

.gallery-image {
  max-width: 500;
  height: 500px;
  -moz-object-fit: cover;
  object-fit: cover;
  display: inline-block;
  margin-left: auto;
  margin-right: auto;
}

.gallery-image:hover {
  position:relative;
  -moz-object-fit:fill;
  object-fit:fill;
  display:flex;
  z-index:auto;
  top: 0;
  left: 0;
  width: 100%;
  height:100%;
}

#carousel-image {
  background-color: rgb(29,31,33);
  border: none;
  display: block;
}

.carousel {
  border-style: none;
  border-color: white;
  border-width: 1px;
}

.carousel-indicators {
  align-items: center;
  margin-bottom: auto;
}

/* MUSIC PAGE */

#music {
  margin-top: 50px;
}

.albumcard {
  background-color: #1D1F21;
  border: none;
}

.albumart {
  margin: auto;
  border-radius: 5px;
  box-shadow: 0px 0px 5px 5px #232323;
}

.albumtitle {
  padding: 15px;
  background-color: #00000008;
  border-bottom: 1px solid #232323;
  border-top-left-radius: 15px;
  border-top-right-radius: 15px;
}

.labeltitle {
  font-size: larger;
}

.collapse-text {
  margin: auto;
}

.album-collapse-text {
  background-color: #00000008;
  font-size: medium;
  font-weight: 100;
  line-height: 180%;
  min-height: 150px;
  max-height: 150px;
  overflow: scroll;
  overflow-x: hidden;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}

.album-buttons {
  display: block;
  margin: auto;
  border: none;
  background-color: #1D1F21;
  width: 150px;
}

.album-buttons:hover {
  background-color: #1D1F21;
}

.album-buttons:focus {
  background-color: #f74749;
}

.albumcollapse {
  display: block;
  margin: auto;
}

.albumfooter {
  display: block;
  margin: auto;
  border: 1px solid #232323;
  border-radius: 15px;
  width: 500px;
}

#music-player {
  width: 50%;
  height: 300px;
}

#spotify {
  color: #1ed760;
}

/* VIDEOS */

#videos {
  overflow: hidden;
  margin-top: 50px;
  /* background-color: rgb(29,31,33);
  background-image: -moz-linear-gradient(rgb(41,47,51), rgb(19, 18, 18)); Gradient Settings
  background-image: -webkit-linear-gradient(rgb(41,47,51), rgb(19, 18, 18)); Gradient Settings
  background-image: -o-linear-gradient(rgb(41,47,51), rgb(19, 18, 18)); Gradient Settings
  background-image: linear-gradient(rgb(41,47,51), rgb(19, 18, 18)); Gradient Settings */
}

.iframes {
  width:420px;
  height:315px;
  margin: 25px;
}

/* TABLET VIEW */

@media (min-width: 481px) and (max-width: 1440px) {

  .navbar-toggler {
    margin-left: auto;
  }

  .nav-name {
    margin-left: 0;
  }

  .homepage {
    background-image: none;
    background-position: right 70px top 222px;
    background-size: 120px 65px;
  }  

  .div2 {
    display: flex;
    flex-direction: column;
    margin: 5px;
    min-height: 55vh;
    max-height: 55vh;
    overflow-y: auto;
  }

  .home-text {
    width: 90%;
  }

  #div1 {
    padding-top: 0;
  }

  .divpics {
    width: 100%;
  }

  #div1vid {
    width: 100%;
  }

  #img-main {
    display: block;
    margin: auto;
  }

  /* PICTURES PAGE */

  #gallery {
    max-width: 100%;
  }

  .gallery-image {
    width: 500px;
    height: 500px;
  }

  .gallery-image:hover {
    width: 100%;
    height: 100%;
    align-content: center;
  }

  /* CONTACT PAGE */

  #contact-photo {
    display: block;
    margin: -3% 0 0 20%;
    width: 75%;
    height: 75%;
  }
  
  .li-form {
    width: 325px;
    margin: 0 0 30px -30px;
    font-size: 21px;
  }

  #frb-form {
    width: 45vw;
    text-align: left;
    margin: 0 0 0 0;
  }

  /* MUSIC PAGE */

  .albumcard {
    margin: 0 0 25px 0;
  }

  .album-collapse-text {
    width: 65vw !important;
  }

  .albumfooter {
    width: 65vw !important;
  }

}

/* MOBILE VIEW */

@media only screen and (max-width: 480px)  {

  /* MAIN STYLING */

  .container {
    flex-direction: column;
  }
    
  .navbar {
    padding-top: -5px;
    min-height: 75px;
  }

  .navbar-nav {
    padding: 5px;
  }
  
  .navbar .nav-name {
    margin-top: -50px;
  }
  
  .nav-logo {
    align-self: flex-start;
  }

  .navbar-toggler {
    align-self: flex-end;
    margin-top: -50px;
  }

  div {
    flex-direction: column;
    align-items: center;
  }

  .div1 {
    padding: 15px 15px 0 15px;
  }
  
  .div2 {
    flex-direction: column;
    word-wrap: break-word;
    margin: 10px;
  }
  
  .div4 {
    flex-direction: column;
  }

  /* HOMEPAGE */

  #div1 {
    padding-top: 0;
    margin-bottom: 25px;
  }

  #div2 {
    padding-top: 0;
  }

  #div2 .header {
    padding-top: 25px;
  }

  #div2 .divpics {
    margin-bottom: 25px;
  }

  #div3 {
    margin-bottom: 35px;
  }

  .divpics {
    width: 85%;
  }

  #div1vid {
    width: 300px;
  }

  .home-text {
    width: 100%;
  }
  
  #img-main {
    width: 90%;
  }

  .card-body {
    flex-direction: column;
  }
  
  .main {
    max-width: 350px;
    margin: auto;
  }

  #subscribe {
    margin-top: 50px;
    margin-bottom: 50px;
  }

  .accordion-item {
    background-color: #1D1F21;
  }

  .sub-button {
    border: none;
  }

  .parent {
    flex-direction: column;
  }
  
  .social {
    width: 100%;
  }
  
  /* CONTACT PAGE */
  
  .div3 {
    flex-direction: column;
    margin-left: auto;
    margin-right: auto;
    font-size: large;
  }

  .contact {
    word-wrap: break-word;
    font-size: large;
    font-family: Baskerville;
  }

  #contact-icons {
    padding: 0 0 0 0;
  }
  
  .contact-photo {
    display: block;
    margin: auto;
    width: 100%;
    padding-bottom: 25px;
  }

  #contact-photo {
    display: block;
    margin: auto;
    width: 100%;
  }

  .li-form {
    list-style-type: none;
    line-height: 50px;
    width: 80vw;
    margin: 0 0 0 -90px;
  }
  
  #frb-form {
    margin: auto;
    width: 77vw;
  }  

  .g-recaptcha {
  display: block;
  margin-left: -20px;
}
  
/* PICTURES PAGE */

  #gallery {
    margin-top: -6vh;
    display: inline-block;
    max-width: 100%;
    position: relative;
  }

  .gallery-image {
    width: 80%;
    height: 80%;
    -moz-object-fit: cover;
    object-fit: cover;
    display: inline-block;
    position: relative;
  }

  .gallery-image:hover {
    width: 100%;
    height: 100%;
    align-content: center;
    -moz-object-fit: cover;
    object-fit: cover;
  }

  .carousel {
    border-style: none;
    border-color: white;
    border-width: 1px;
  }
  
  .carousel-indicators {
    display: block;
    margin: auto;
  }
  
  /* MUSIC PAGE */

  #music-player {
    width: 100%;
    height: 300px;
  }

  .albumcard {
    margin: 0 0 25px 0;
  }

  .albumart {
    width: 90% !important;
    height: 90% !important;
  }

  .album-buttons {
    width: 50%;
  }

  .album-collapse-text {
    width: 85vw !important;
  }

  .albumfooter {
    width: 85vw !important;
  }
  
  /* VIDEOS PAGE */

  .videos {
    overflow: hidden;
  }

  .iframes {
    width: auto;
    height: auto;
    margin: auto;
    padding: 15px 0 15px 0;
  }

}